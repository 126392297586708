import { mapActions, mapState } from 'vuex';
import CreateClassificationDialog from '../../elements/CreateClassificationDialog';
import UpdateClassificationDialog from '../../elements/UpdateClassificationDialog';
import DeleteClassificationDialog from '../../elements/DeleteClassificationDialog';
import ImportMassiveClasificationDialog from '../../elements/ImportMassiveClassificationDialog';
import { showError } from '@/helpers/globalHelpers';

export default {
	name: 'ClassificationTab',
	data() {
		return {
			loading: false,
			dialog: false,
			headers: [
				{
					text: 'Nombre de Clasificación',
					align: 'start',
					sortable: false,
					value: 'name',
				},
				{
					text: 'Área',
					align: 'start',
					sortable: false,
					value: 'area.name',
				},
				{
					text: 'Responsable',
					align: 'start',
					sortable: false,
					value: 'responsible_id.fullName',
				},
				{
					text: 'Acciones',
					align: 'start',
					sortable: false,
					value: 'actions',
				},
			],

			editDialog: false,
			deleteDialog: false,
			editedItem: {},
			limitOptions: [5, 10, 20, 50],
			classificationsfilters: {
				page: 1,
				limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
				pagination: true,
				area: null,
				//q: '',
			},
		};
	},
	computed: {
		...mapState('classification', [
			'classifications',
			'classificationsPagination',
			'loadingClassification',
			'filtersClassification',
		]),
		...mapState('area', ['allAreas']),
		sortedAllAreas() {
			return [...this.allAreas].sort((a, b) => a.name.localeCompare(b.name));
		},
		filters: {
			get() {
				return this.filtersClassification || {};
			},
			set(value) {
				this.$store.commit('classification/SET_FILTER_CLASSIFICATION', {
					...value,
				});
			},
		},
		hasEditPermission() {
			return this.$store.getters['auth/hasActivity'](
				'permisosAplicacion',
				'listaClasificacion',
				'editarClasificacion'
			);
		},
		hasDeletePermission() {
			return this.$store.getters['auth/hasActivity'](
				'permisosAplicacion',
				'listaClasificacion',
				'eliminarClasificacion'
			);
		},
	},
	created() {
		this.getClassifications();
		this.listAllAreas();
		this.getResponsibles();
	},
	methods: {
		...mapActions('classification', ['listClassification', 'cleanClassifications']),
		...mapActions('user',['getResponsibles']),
		...mapActions('area', ['listAllAreas']),
		editItem(item) {
			this.editedItem = Object.assign({}, item);
			this.editDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		deleteItem(item) {
			this.editedItem = Object.assign({}, item);
			this.deleteDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		async closeDeleteDialog() {
			if (
				this.classifications.length == 1 &&
				this.classificationsfilters.page > 1
			) {
				this.classificationsfilters.page = this.classificationsfilters.page - 1;
			}
			await this.getClassifications();
		},
		async getClassifications() {
			this.loading = true;
			const { error } = await this.listClassification(this.classificationsfilters);
			if (error) showError(error);
			this.loading = false;
		},
		async closeCreateDialog() {
			this.classificationsfilters.page = 1;
			await this.getClassifications();
		},
		async searchClassifications() {
			this.classificationsfilters.page = 1;
			await this.getClassifications();
		},
	},
	beforeDestroy() {
		this.cleanClassifications();
	},
	components: {
		CreateClassificationDialog,
		DeleteClassificationDialog,
		UpdateClassificationDialog,
		ImportMassiveClasificationDialog
	},
};
